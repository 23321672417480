import { Image } from "@clipboard-health/ui-react";
import { Box, Stack } from "@mui/material";
import { Banner } from "@src/appV2/lib/Alert";
import { type ReactElement, type ReactNode } from "react";

import boxImage from "../assets/images/box.png";

export interface BannerContentProps {
  isLoading?: boolean;
  children: ReactNode;
}

export function BannerContent(props: BannerContentProps): ReactElement {
  const { isLoading, children } = props;

  return (
    <Banner severity="success">
      <Box p={2}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
          <Image
            src={boxImage}
            alt="Onboarding Reward"
            flexShrink={0}
            sx={{
              "@keyframes myAnim": {
                "0%": { transform: "rotate(0deg)", transformOrigin: "50% 100%" },
                "10%": { transform: "rotate(2deg)" },
                "30%": { transform: "rotate(4deg)" },
                "20%": { transform: "rotate(-4deg)" },
                "40%": { transform: "rotate(-4deg)" },
                "50%": { transform: "rotate(4deg)" },
                "60%": { transform: "rotate(-4deg)" },
                "70%": { transform: "rotate(4deg)" },
                "80%": { transform: "rotate(-2deg)" },
                "90%": { transform: "rotate(2deg)" },
                "100%": { transform: "rotate(0deg)", transformOrigin: "50% 100%" },
              },
              animation: isLoading ? "myAnim 2s ease 0s infinite normal forwards" : "",
            }}
          />
          <Box textAlign="left">{children}</Box>
        </Stack>
      </Box>
    </Banner>
  );
}
