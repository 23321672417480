import { toError } from "@clipboard-health/util-ts";
import { get } from "@src/appV2/api/api";
import { environmentConfig, isDevelopmentNodeEnvironment } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics";
import { useQuery, type UseQueryOptions, type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

import { getWorkerResponseSchema, type Worker } from "./types";

export const GET_WORKER_PATH = "/agentProfile";

// There is a known design misconception that /agentProfile endpoint returns 200 with empty string as response during onboarding
// It doesn't crash the app, but it's not a valid response so it fails zod validation and shows error boundary in development environment
// This is blocking the app from loading in the onboarding flow and raises questions for developers about why this is happening
// We should fix this endpoint to return 404 when agent is not found
export const EMPTY_STRING_WORKER_ERROR = "Empty string response from /agentProfile endpoint";

export function useGetWorker(options: UseQueryOptions<Worker> = {}): UseQueryResult<Worker> {
  return useQuery({
    queryKey: [GET_WORKER_PATH],
    queryFn: async () => {
      const response = await get<undefined, Worker | string>({
        url: `${environmentConfig.REACT_APP_BASE_API_URL}${GET_WORKER_PATH}`,
        responseSchema: getWorkerResponseSchema.or(z.string()),
      });

      if (typeof response.data === "string") {
        throw new TypeError(EMPTY_STRING_WORKER_ERROR);
      }

      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_WORKER_FAILURE,
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    useErrorBoundary: (maybeError: unknown) => {
      if (!isDevelopmentNodeEnvironment()) {
        return false;
      }

      const error = toError(maybeError);

      return error.message !== EMPTY_STRING_WORKER_ERROR;
    },
    ...options,
  });
}
