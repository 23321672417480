import { Text } from "@clipboard-health/ui-react";
import { type ReactNode } from "react";

interface BannerTextProps {
  children: ReactNode;
}

export function BannerText(props: BannerTextProps) {
  const { children } = props;

  return (
    <Text
      sx={{
        color: (theme) => theme.palette.background.paper,
        fontSize: (theme) => theme.typography.fontSize,
      }}
    >
      {children}
    </Text>
  );
}
