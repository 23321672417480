import { parseISO } from "date-fns";

import { AvailabilityCalendarCellStatus } from "../types";

export function getAvailableDateStringsFromCurrentDates(
  currentDates: Record<string, AvailabilityCalendarCellStatus>
): string[] {
  return Object.keys(currentDates).filter((dateId) => {
    return currentDates[dateId] === AvailabilityCalendarCellStatus.AVAILABLE;
  });
}

export function getAvailableDatesFromCurrentDates(
  currentDates: Record<string, AvailabilityCalendarCellStatus>
): Date[] {
  const availableDateIds = getAvailableDateStringsFromCurrentDates(currentDates);
  return availableDateIds.map((dateId) => parseISO(dateId));
}
