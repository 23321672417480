import { Image, Text, Title, type UseModalState } from "@clipboard-health/ui-react";
import { Button, DialogContent, Stack } from "@mui/material";
import { FullScreenDialog } from "@src/appV2/lib";
import { type ReactElement } from "react";

import boxImage from "./assets/images/box-big.png";

export interface OnboardingBonusAvailableDialogProps {
  modalState: UseModalState;
  onClose: () => void;
}

export function OnboardingBonusAvailableDialog(
  props: OnboardingBonusAvailableDialogProps
): ReactElement {
  const { modalState, onClose } = props;

  return (
    <FullScreenDialog modalState={modalState}>
      <DialogContent>
        <Stack
          m={4}
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          spacing={4}
        >
          <Title bold component="h1" textAlign="center">
            Yay, you qualify for a sign-up bonus!
          </Title>

          <Image src={boxImage} alt="Onboarding Reward" />

          <Stack spacing={4}>
            <Text textAlign="center">
              Which means you&apos;ll make $ with every step you complete.
            </Text>

            <Text textAlign="center">
              Once you finish all the steps you can cash out your bonus!
            </Text>
          </Stack>

          <Stack width="100%">
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                onClose();
              }}
            >
              Continue
            </Button>
          </Stack>

          <Text textAlign="center" variant="body2">
            Legal disclaimer: If we determine you are signing up with a duplicate account or false
            information, we reserve the right to retract this bonus offer. This bonus offer is not
            offered to everyone.
          </Text>
        </Stack>
      </DialogContent>
    </FullScreenDialog>
  );
}
