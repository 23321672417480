import { AvailabilityCalendarCellStatus } from "../types";

/**
 * Merges API data with modified dates, prioritizing on the user modified dates.
 *
 * @param savedAvailableDates array of date strings in the 'yyyy-MM-dd' format
 * @param modifiedDates Record where the key is a date string in the 'yyyy-MM-dd' format and the value is the status of the cell
 * @returns A record after merging modified dates and saved availabilities
 */
export function mergeSavedAvailabilitiesAndModifiedDates(
  savedAvailableDates: string[],
  modifiedDates: Record<string, AvailabilityCalendarCellStatus>
): Record<string, AvailabilityCalendarCellStatus> {
  const savedAvailableDatesStatusMap: Record<string, AvailabilityCalendarCellStatus> = {};
  savedAvailableDates.forEach((savedAvailableDate) => {
    savedAvailableDatesStatusMap[savedAvailableDate] = AvailabilityCalendarCellStatus.AVAILABLE;
  });
  return { ...savedAvailableDatesStatusMap, ...modifiedDates };
}
