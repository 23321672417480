import { InternalLink } from "@clipboard-health/ui-react";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { type ReactElement } from "react";

import { ACCOUNTS_DOCUMENTS_FULL_PATH } from "../../Accounts/Documents/paths";

interface MissingRequirementsProps {
  licenseActive: boolean;
  paymentInfoProvided: boolean;
  documentsVerified: boolean;
}

export function MissingRequirements(params: MissingRequirementsProps): ReactElement {
  const { licenseActive, paymentInfoProvided, documentsVerified } = params;
  const elements: ReactElement[] = [];

  if (!paymentInfoProvided) {
    elements.push(
      <>
        <InternalLink
          to={DeprecatedGlobalAppV1Paths.SETUP_STRIPE}
          color="inherit"
          sx={{
            textDecoration: "underline",
            textDecorationColor: (theme) => theme.palette.background.paper,
            fontWeight: (theme) => theme.typography.fontWeightBold,
          }}
        >
          Stripe
        </InternalLink>{" "}
        is setup
      </>
    );
  }

  if (!documentsVerified) {
    elements.push(
      <>
        <InternalLink
          to={ACCOUNTS_DOCUMENTS_FULL_PATH}
          color="inherit"
          sx={{
            textDecoration: "underline",
            textDecorationColor: (theme) => theme.palette.background.paper,
            fontWeight: (theme) => theme.typography.fontWeightBold,
          }}
        >
          documents
        </InternalLink>{" "}
        are added and approved
      </>
    );
  }

  if (!licenseActive) {
    elements.push(
      <>
        your{" "}
        <InternalLink
          to={DeprecatedGlobalAppV1Paths.LICENSE_MANAGER}
          color="inherit"
          sx={{
            textDecoration: "underline",
            textDecorationColor: (theme) => theme.palette.background.paper,
            fontWeight: (theme) => theme.typography.fontWeightBold,
          }}
        >
          license
        </InternalLink>{" "}
        is active
      </>
    );
  }

  switch (elements.length) {
    case 0: {
      return <>all requirements are complete</>;
    }

    case 1: {
      return elements[0];
    }

    case 2: {
      return (
        <>
          {elements[0]} and {elements[1]}
        </>
      );
    }

    default: {
      return (
        <>
          {elements[0]}, {elements[1]} and {elements[2]}
        </>
      );
    }
  }
}
