import { type Worker } from "@src/appV2/Worker/api/types";
import { useGetWorker } from "@src/appV2/Worker/api/useGetWorker";

export const useDefinedWorker = (): Worker => {
  const { data } = useGetWorker();

  if (!data) {
    throw new Error(
      "useDefinedWorker returned null. Either consecutive call for getting worker has failed or this hook was used inside public route"
    );
  }

  return data;
};
