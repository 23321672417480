import { patch } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

import {
  MioOnboardStage,
  MioPayoutState,
  type WorkerOnboardingResponse,
  workerOnboardingResponseSchema,
} from "../types";

export interface PatchWorkerOnboardingParams {
  workerId: string;
}

export const patchWorkerOnboardingRequestSchema = z.object({
  data: z.object({
    type: z.literal("worker-onboarding"),
    id: z.string(),
    attributes: z.object({
      mioOnboardStage: z.optional(z.literal(MioOnboardStage.LICENSE_PROVIDED)),
      mioPayoutState: z.optional(z.literal(MioPayoutState.PAID_OUT)),
    }),
  }),
});

export type PatchWorkerOnboardingRequest = z.infer<typeof patchWorkerOnboardingRequestSchema>;

export function usePatchWorkerOnboarding(
  params: PatchWorkerOnboardingParams,
  options: UseMutationOptions<
    WorkerOnboardingResponse,
    AxiosError,
    PatchWorkerOnboardingRequest
  > = {}
): UseMutationResult<WorkerOnboardingResponse, AxiosError, PatchWorkerOnboardingRequest> {
  const { workerId } = params;

  return useMutation({
    mutationFn: async (data: PatchWorkerOnboardingRequest) => {
      const response = await patch({
        url: `${environmentConfig.REACT_APP_BASE_API_URL}/workers/${workerId}/onboarding`,
        data,
        requestSchema: patchWorkerOnboardingRequestSchema,
        responseSchema: workerOnboardingResponseSchema,
      });
      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.PATCH_WORKER_ONBOARDING_FAILURE,
    },
    ...options,
  });
}
