import { USD } from "@dinero.js/currencies";
import { type Dinero, dinero } from "dinero.js";

// Replace this file with code from @clipboard-health/money when the package is updated.
// https://linear.app/clipboardhealth/issue/SPRTA-503/enable-use-of-clipboard-healthmoney-in-the-mobile-app

export type Money = Dinero<number>;

export interface MoneyDto {
  amountInMinorUnits: number;
  currencyCode: "USD";
}

export function moneyDtoToMoney(moneyDto: MoneyDto): Money {
  const { amountInMinorUnits } = moneyDto;
  return dinero({ amount: amountInMinorUnits, currency: USD });
}

export const ZERO_USD = dinero({ amount: 0, currency: USD });

export { add } from "dinero.js";
