import { ModalStatus, type UseModalState, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { type UseQueryResult } from "@tanstack/react-query";
import constate from "constate";
import { useEffect } from "react";

import { useGetWorkerOnboarding } from "./api/useGetWorkerOnboarding";
import { usePatchWorkerOnboarding } from "./api/usePatchWorkerOnboarding";
import { mapToOnboardingState } from "./helpers/mapToOnboardingState";
import { type MioOnboardStage, MioPayoutState, type WorkerOnboardingResponse } from "./types";

interface UseWorkerOnboardingContext {
  onboardingBannerModalState: UseModalState;
  getWorkerOnboardingQuery: UseQueryResult<WorkerOnboardingResponse>;
  onboardMio: (mioOnboardStage: MioOnboardStage.LICENSE_PROVIDED) => Promise<boolean>;
}

interface WorkerOnboardingParams {
  workerId?: string;
}

function useWorkerOnboarding(params: WorkerOnboardingParams): UseWorkerOnboardingContext {
  const { workerId } = params;
  const onboardingBannerModalState = useModalState();

  const getWorkerOnboardingQuery = useGetWorkerOnboarding(
    {
      workerId: workerId ?? "",
    },
    {
      refetchOnWindowFocus: false,
      enabled: isDefined(workerId),
    }
  );

  const { data: onboardingData, isSuccess } = getWorkerOnboardingQuery;

  const onboardingState = isSuccess ? mapToOnboardingState(onboardingData) : undefined;
  const mioEnabled = onboardingState?.enabled;
  const mioPayoutState = onboardingState?.mioPayoutState;
  const { setModalStatus: setOnboardingBannerStatus } = onboardingBannerModalState;

  const { mutateAsync } = usePatchWorkerOnboarding({ workerId: workerId ?? "" });

  async function onboardMio(mioOnboardStage: MioOnboardStage.LICENSE_PROVIDED) {
    const workerOnboarding = await mutateAsync({
      data: {
        type: "worker-onboarding",
        id: workerId ?? "",
        attributes: {
          mioOnboardStage,
        },
      },
    });

    const mioState = mapToOnboardingState(workerOnboarding);
    if (mioState.enabled) {
      onboardingBannerModalState.openModal();
    }

    return mioState.enabled;
  }

  // On app load check if we need to show the banner and the dialog.
  useEffect(() => {
    setOnboardingBannerStatus(
      mioEnabled === true && mioPayoutState !== MioPayoutState.PAID_OUT
        ? ModalStatus.OPEN
        : ModalStatus.CLOSED
    );
  }, [mioEnabled, mioPayoutState, setOnboardingBannerStatus]);

  return {
    onboardingBannerModalState,
    getWorkerOnboardingQuery,
    onboardMio,
  };
}

export const [WorkerOnboardingProvider, useWorkerOnboardingContext] = constate(useWorkerOnboarding);
