import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { type UseQueryResult } from "@tanstack/react-query";

import { type WorkerOnboardingResponse, workerOnboardingResponseSchema } from "../types";

export interface GetWorkerOnboardingParams {
  workerId: string;
}

export function useGetWorkerOnboarding(
  params: GetWorkerOnboardingParams,
  options: UseGetQueryOptions<WorkerOnboardingResponse> = {}
): UseQueryResult<WorkerOnboardingResponse> {
  const { workerId } = params;

  return useGetQuery({
    url: `${environmentConfig.REACT_APP_BASE_API_URL}/workers/${workerId}/onboarding`,
    responseSchema: workerOnboardingResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_WORKER_ONBOARDING_FAILURE,
      userErrorMessage: "Something went wrong while loading bonus details",
    },
    ...options,
  });
}
