import { isDefined } from "@clipboard-health/util-ts";
import { APP_V2_APP_EVENTS, logError } from "@src/appV2/lib/analytics";
import { type Worker } from "@src/appV2/Worker/api/types";
import { GET_WORKER_PATH } from "@src/appV2/Worker/api/useGetWorker";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

export const useUpdateWorkerCache = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (data: Partial<Worker>) => {
      queryClient.setQueryData<Worker | undefined>([GET_WORKER_PATH], (cachedWorker) => {
        if (!isDefined(cachedWorker)) {
          logError(APP_V2_APP_EVENTS.UPDATE_NON_EXISTENT_WORKER, {
            error: new Error("Failed to update worker as it was not fetched yet"),
            metadata: {
              properties: Object.keys(data),
            },
          });

          return cachedWorker;
        }

        return {
          ...cachedWorker,
          ...data,
        };
      });
    },
    [queryClient]
  );
};
