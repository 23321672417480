import { Span } from "@clipboard-health/ui-react";
import { toDecimal } from "dinero.js";
import { type ReactElement } from "react";

import { type Money } from "../helpers/money";

export interface BonusTextProps {
  amount: Money;
}

export function BonusText(props: BonusTextProps): ReactElement {
  const { amount } = props;

  const formatted = toDecimal(amount, ({ value, currency }) => {
    const currencyCode = currency.code === "USD" ? "$" : currency.code;
    return `${currencyCode}${Number(value).toFixed(2)}`;
  });

  return <Span sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>{formatted}</Span>;
}
