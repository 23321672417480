import { ExternalLink, LoadingButton, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, Button } from "@mui/material";
import { AxiosError } from "axios";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { DeprecatedGlobalAppV1Paths } from "../App/paths";
import { useToast } from "../lib";
import { SupportArticleLinks } from "../support/constants";
import { usePatchWorkerOnboarding } from "./api/usePatchWorkerOnboarding";
import { BannerContent } from "./components/BannerContent";
import { BannerText } from "./components/BannerText";
import { BonusText } from "./components/BonusText";
import { MissingRequirements } from "./components/MissingRequirements";
import { PayoutDeniedDialog } from "./components/PayoutDeniedDialog";
import { useWorkerOnboardingContext } from "./context";
import { mapToOnboardingState } from "./helpers/mapToOnboardingState";
import { add as addMoney } from "./helpers/money";
import { MioPayoutState, MioStages } from "./types";

interface OnboardingIncentiveBannerProps {
  workerId?: string;
  stage: MioStages;
}

export function OnboardingIncentiveBanner(props: OnboardingIncentiveBannerProps) {
  const { workerId, stage } = props;

  const { showInfoToast, showErrorToast } = useToast();
  const history = useHistory();
  const payoutDeniedDialogModalState = useModalState();

  const { onboardingBannerModalState, getWorkerOnboardingQuery } = useWorkerOnboardingContext();

  const {
    isLoading,
    isError,
    refetch,
    data: onboardingData,
    isFetching,
  } = getWorkerOnboardingQuery;

  const { isLoading: isClaimingBonus, mutateAsync: claimBonus } = usePatchWorkerOnboarding({
    workerId: workerId ?? "",
  });

  const { modalIsOpen: onboardingBannerIsOpen, modalIsClosed: onboardingBannerIsClosed } =
    onboardingBannerModalState;

  useEffect(() => {
    // Trigger refetch of data to get the latest status
    // as the user navigates in the app.
    if (onboardingBannerIsOpen) {
      void refetch();
    }
  }, [onboardingBannerIsOpen, refetch]);

  if (onboardingBannerIsClosed) {
    return null;
  }

  if (isLoading || isFetching) {
    if (stage === MioStages.FINAL) {
      return null;
    }

    return <BannerContent isLoading>Yay, you qualify for a sign-up bonus!</BannerContent>;
  }

  if (isError) {
    return (
      <BannerContent>
        Something went wrong.{" "}
        <Button
          variant="text"
          sx={{
            textDecoration: "underline",
            textDecorationColor: (theme) => theme.palette.background.paper,
            fontSize: (theme) => theme.typography.body2.fontSize,
            lineHeight: (theme) => theme.typography.body2.lineHeight,
            fontWeight: (theme) => theme.typography.fontWeightBold,
            color: (theme) => theme.palette.background.paper,
            padding: 0,
          }}
          onClick={async () => {
            await refetch();
          }}
        >
          Try again
        </Button>
        .
      </BannerContent>
    );
  }

  const { bonuses, mioPayoutState, licenseActive, paymentInfoProvided, documentsVerified } =
    mapToOnboardingState(onboardingData);
  const totalBonus = [bonuses.professionalInfo, bonuses.paymentInfo, bonuses.documents].reduce(
    (accumulator, element) => addMoney(accumulator, element)
  );

  const hasMissingRequirements = !licenseActive || !paymentInfoProvided || !documentsVerified;

  async function handleClaimBonus(): Promise<void> {
    try {
      await claimBonus({
        data: {
          type: "worker-onboarding",
          id: workerId ?? "",
          attributes: {
            mioPayoutState: MioPayoutState.PAID_OUT,
          },
        },
      });
      onboardingBannerModalState.closeModal();
      showInfoToast('Your bonus will appear in the "Earnings" section shortly.');
      history.replace(DeprecatedGlobalAppV1Paths.ACCOUNT);
    } catch (error: unknown) {
      const isPayoutDenied =
        isDefined(error) && error instanceof AxiosError && error?.response?.status === 422;

      if (isPayoutDenied) {
        payoutDeniedDialogModalState.openModal();
      } else {
        showErrorToast(
          <>
            There was an error when claiming your bonus. Try again or{" "}
            <ExternalLink
              sx={{
                color: (theme) => theme.palette.background.paper,
                textDecorationColor: (theme) => theme.palette.background.paper,
              }}
              to={SupportArticleLinks.HOW_TO_CONTACT_SUPPORT}
            >
              contact our 24/7 Support Team
            </ExternalLink>
            .
          </>
        );
      }
    }
  }

  return (
    <>
      <PayoutDeniedDialog modalState={payoutDeniedDialogModalState} />
      <BannerContent>
        {stage === MioStages.PROFESSIONAL_INFO_PROVIDED && (
          <BannerText>
            <BonusText amount={bonuses.professionalInfo} /> earned! We&apos;re upping the next step
            bonus to <BonusText amount={bonuses.paymentInfo} />.
          </BannerText>
        )}

        {stage === MioStages.PAYMENT_INFO_MAYBE_PROVIDED && paymentInfoProvided && (
          <BannerText>
            Boom, <BonusText amount={addMoney(bonuses.professionalInfo, bonuses.paymentInfo)} />{" "}
            earned! One last step to earn an additional <BonusText amount={bonuses.documents} /> and
            then cash out!
          </BannerText>
        )}

        {stage === MioStages.PAYMENT_INFO_MAYBE_PROVIDED && !paymentInfoProvided && (
          <BannerText>
            Boom, you&apos;ve got <BonusText amount={bonuses.professionalInfo} /> in your account!
            One last step to earn another <BonusText amount={bonuses.documents} /> and then cash
            out!
          </BannerText>
        )}

        {stage === MioStages.FINAL && hasMissingRequirements && (
          <BannerText>
            You&apos;ll be able to withdraw <BonusText amount={totalBonus} /> from the
            &quot;Earnings&quot; section after{" "}
            <MissingRequirements
              licenseActive={licenseActive}
              paymentInfoProvided={paymentInfoProvided}
              documentsVerified={documentsVerified}
            />
            .
          </BannerText>
        )}

        {stage === MioStages.FINAL && mioPayoutState === MioPayoutState.READY_TO_PAY_OUT && (
          <Box display="flex" alignItems="center" gap={2}>
            <BannerText>
              Your <BonusText amount={totalBonus} /> sign up bonus is ready!
            </BannerText>
            <LoadingButton
              isLoading={isClaimingBonus}
              variant="contained"
              size="small"
              sx={{
                flexShrink: 0,
                fontSize: (theme) => theme.typography.body2.fontSize,
                color: (theme) => theme.palette.background.paper,
                backgroundColor: (theme) => theme.palette.success.light,
                borderRadius: "4px",
                ":hover": {
                  backgroundColor: (theme) => theme.palette.success.light,
                },
              }}
              onClick={async () => {
                await handleClaimBonus();
              }}
            >
              Cash out
            </LoadingButton>
          </Box>
        )}
      </BannerContent>
    </>
  );
}
