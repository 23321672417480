export enum AvailabilityCalendarCellStatus {
  AVAILABLE = "available",
  UNAVAILABLE = "unavailable",
}

export interface WorkerAvailabilityCalendarConfig {
  upperThresholdMarkedDays: number;
  upperThresholdDays: number;
  lowerThresholdMarkedDays: number;
  lowerThresholdDays: number;
}
