import { Text, type UseModalState } from "@clipboard-health/ui-react";
import { Button } from "@mui/material";

import { CommonDialog } from "../../lib";
import { SupportContext } from "../../support/constants";
import { HowToContactSupportLink } from "../../support/HowToContactSupportLink";

export interface PayoutDeniedDialogProps {
  modalState: UseModalState;
}

export function PayoutDeniedDialog(props: PayoutDeniedDialogProps) {
  const { modalState } = props;

  return (
    <CommonDialog
      modalState={modalState}
      title="Payout denied"
      actions={
        <>
          <HowToContactSupportLink supportContext={SupportContext.SIGNUP_BONUS_ERROR} />
          <Button
            fullWidth
            variant="outlined"
            onClick={() => {
              modalState.closeModal();
            }}
          >
            Close
          </Button>
        </>
      }
    >
      <Text>The payout of the bonus was denied. It looks like this is a duplicate account.</Text>
      <Text sx={{ mt: 2 }}>
        If you think this is an error, please reach out to our Support Team.
      </Text>
    </CommonDialog>
  );
}
