import { type OnboardingState, type WorkerOnboardingResponse } from "../types";
import { moneyDtoToMoney } from "./money";

export function mapToOnboardingState(workerOnboarding: WorkerOnboardingResponse): OnboardingState {
  const {
    data: {
      attributes: {
        paymentInfoProvided,
        licenseActive,
        documentsVerified,
        mioEnabled,
        mioBonuses: { professionalInfo, paymentInfo, documents },
        mioOnboardStage,
        mioPayoutState,
      },
    },
  } = workerOnboarding;
  return {
    paymentInfoProvided,
    licenseActive,
    documentsVerified,
    enabled: mioEnabled,
    bonuses: {
      professionalInfo: moneyDtoToMoney(professionalInfo),
      paymentInfo: moneyDtoToMoney(paymentInfo),
      documents: moneyDtoToMoney(documents),
    },
    mioOnboardStage,
    mioPayoutState,
  };
}
