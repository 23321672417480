import { z } from "zod";

import { type Money } from "./helpers/money";

export enum MioOnboardStage {
  ADDRESS_PROVIDED = "ADDRESS_PROVIDED",
  LICENSE_PROVIDED = "LICENSE_PROVIDED",
}

const mioOnboardStageSchema = z.nativeEnum(MioOnboardStage);

export enum MioPayoutState {
  NOT_READY = "NOT_READY",
  READY_TO_PAY_OUT = "READY_TO_PAY_OUT",
  PAID_OUT = "PAID_OUT",
}

const mioPayoutStateSchema = z.nativeEnum(MioPayoutState);

export const moneyDtoSchema = z.object({
  amountInMinorUnits: z.number(),
  currencyCode: z.literal("USD"),
});

export const workerOnboardingAttributesSchema = z.object({
  paymentInfoProvided: z.boolean(),
  licenseActive: z.boolean(),
  documentsVerified: z.boolean(),
  mioEnabled: z.boolean(),
  mioBonuses: z.object({
    professionalInfo: moneyDtoSchema,
    paymentInfo: moneyDtoSchema,
    documents: moneyDtoSchema,
  }),
  mioOnboardStage: z.optional(mioOnboardStageSchema),
  mioPayoutState: mioPayoutStateSchema,
});

export const workerOnboardingSchema = z.object({
  type: z.literal("worker-onboarding"),
  id: z.string(),
  attributes: workerOnboardingAttributesSchema,
});

export const workerOnboardingResponseSchema = z.object({
  data: workerOnboardingSchema,
});

export type WorkerOnboarding = z.infer<typeof workerOnboardingSchema>;
export type WorkerOnboardingResponse = z.infer<typeof workerOnboardingResponseSchema>;

export enum MioStages {
  PROFESSIONAL_INFO_PROVIDED,
  PAYMENT_INFO_MAYBE_PROVIDED,
  FINAL,
}

export interface OnboardingState {
  enabled: boolean;
  bonuses: {
    professionalInfo: Money;
    paymentInfo: Money;
    documents: Money;
  };
  mioOnboardStage?: MioOnboardStage;
  mioPayoutState: MioPayoutState;
  paymentInfoProvided: boolean;
  licenseActive: boolean;
  documentsVerified: boolean;
}
